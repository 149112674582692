import Classifiers from '../classifiers';
import Events, { EventTypes } from './events';
import { isObject } from './utils';
import Logger from './logger';

const INTERVAL_FOR_CONTINUOUS_IN_MS = 5000;

export default class Classifier {
  /**
   * Responsible for building up context about the current page.
   * This might include requesting contexts from external services
   *
   * @param {{ app: ConcertAds }}
   */
  constructor({ app }) {
    this.app = app;
    this.classifiers = app.settings.classifiers;
    this.completeCallbacks = [];
    this.complete = false;

    Events.on(EventTypes.installing, () => {
      const classifyingPromises = (this.classifiers || []).map(c => {
        return this.processClassifier(c);
      });

      Promise.all(classifyingPromises).then(() => {
        Events.emit(EventTypes.allClassifierValuesAdded);

        this.complete = true;
        this.completeCallbacks.forEach(f => f.call(this.app));
        this.completeCallbacks = [];
      });
    });
  }

  /**
   * Returns a promise that will be resolved once classification is completed
   * @return {Promise}      Resolved when classification is complete
   */
  blockUntilComplete() {
    return new Promise(resolve => {
      if (this.complete) {
        resolve();
      } else {
        this.completeCallbacks.push(resolve);
      }
    });
  }

  processClassifier(config) {
    return new Promise(resolve => {
      const key = config.key || config.name;
      const when = config.when;
      const topLevelClassifiersFromConfig = config.classifiers || [];
      const timeout = config.timeout || 100;

      const timeoutTimer = setTimeout(() => {
        Logger.log(`Classifier ${key} timeout after ${timeout}`);
        resolve();
      }, timeout);

      switch (when) {
        case 'immediate':
          Promise.all(
            topLevelClassifiersFromConfig.map(c => {
              return this.runClassifier(c, key);
            })
          ).then(() => {
            clearTimeout(timeoutTimer);
            resolve();
          });
          break;

        case 'continuous':
          Promise.all(
            topLevelClassifiersFromConfig.map(c => {
              return this.runClassifier(c, key);
            })
          ).then(() => {
            clearTimeout(timeoutTimer);
            resolve();
          });

          setInterval(() => {
            topLevelClassifiersFromConfig.forEach(c => {
              this.runClassifier(c, key);
            });
          }, INTERVAL_FOR_CONTINUOUS_IN_MS);
          break;

        case 'load':
          onLoad(() => {
            topLevelClassifiersFromConfig.forEach(c => {
              this.runClassifier(c, key);
            });
          });
          clearTimeout(timeoutTimer);
          resolve();
          break;

        default:
          Logger.error(`'when' must be one of 'immediate', 'continuous', 'load'... was ${when}`);
          resolve();
      }
    });
  }

  runClassifier(classifierConfig, key) {
    Logger.log(`Running classifier ${key}.`);
    const resolverFunction = isObject(classifierConfig) ? Object.keys(classifierConfig).shift() : classifierConfig;

    if (!Classifiers[resolverFunction]) {
      Logger.error(`Classifier function ${resolverFunction} does not exist`);
      return;
    }

    // TODO: Move this validation responsibility to a base Classifier class in a future refactor.
    if (isObject(classifierConfig) && classifierConfig.key) {
      Logger.warn(
        `Classifier config for ${resolverFunction} contains property 'key' which will be overidden. Please use a different property name.`
      );
    }

    const classifierArguments = isObject(classifierConfig) ? { ...classifierConfig[resolverFunction], key } : { key };
    return Classifiers[resolverFunction]
      .call(this.app, classifierArguments)
      .then(value => this.addValue(key, value, resolverFunction));
  }

  addValue(key, value, classifierName) {
    if (!value) {
      Logger.log(`Skipping 'false' targeting targeting ${key}`);
      return;
    }

    if (typeof value === 'object' && !Array.isArray(value)) {
      Object.keys(value).forEach(k => this.addValue(k, value[k], classifierName));
      return;
    }

    const previousValue = this.app.getVariable(key);
    const newValue = previousValue ? combineValues(previousValue, value) : value;

    this.app.addVariable(key, newValue);

    Events.emit(EventTypes.classifierValueAdded, {
      key,
      classifierName,
      value: newValue,
    });
  }
}

/**
 * Fire an event on window load, or immediately if window has already loaded.
 * @param {function} fn
 */
function onLoad(fn) {
  if (document.readyState === 'complete') {
    fn();
  } else {
    window.addEventListener('load', fn);
  }
}

/**
 * Combine two values, which can either be strings or an array of strings.
 * If EITHER of the values is an array, then the resulting combination will be
 * an array.
 *
 * @param {string|string[]} one First value
 * @param {string|string[]} two Second value
 */
function combineValues(one, two) {
  if (Array.isArray(one) && Array.isArray(two)) {
    return one.concat(two);
  }

  if (Array.isArray(one) && typeof two === 'string') {
    return one.push(two);
  }

  if (typeof one === 'string' && Array.isArray(two)) {
    return [one, ...two];
  }

  if (typeof one === 'string' && typeof two === 'string') {
    return one + ' ' + two;
  }
}
