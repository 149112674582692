import { incrementSessionDepth, devtools } from '../../lib/utils';
import NativeAds from '../../lib/native_ads';
import Slots from '../../lib/slots';
import SlotNames from '../../lib/slot_names';
import Logger from '../../lib/logger';
import Lifecycle from '../../lib/lifecycle';
import PluginLoader from '../../lib/plugin_loader';
import store from '../../lib/store';
import settings from '../../lib/settings';
import { PrivacyCompliance } from 'data-privacy-compliance';
import { installAdBlockerDetection } from '../../lib/ads_blocker_detector';
import Metrics from '../../lib/metrics';

export function initMixin(ConcertAds) {
  ConcertAds.prototype._init = function(config = {}) {
    // Resets the store. Particularly useful for tests.
    store.reset();
    // Define top-level settings getter and setters on the app instance.
    // This allows us to leverage the CascadingConfig of settings,
    // but without modifying the entire library to use its API.
    Object.defineProperty(this, 'settings', {
      get() {
        return settings.get();
      },
      set(value) {
        settings.set(value);
      },
    });

    settings.add({
      prebid: {},
      slots: [],
      classifiers: [],
      ...config,
    });

    if (!this.settings.isPreview) {
      PrivacyCompliance.useLogger((...args) => {
        Logger.log('PrivacyCompliance: ' + args.join(', '));
      });

      try {
        // PrivacyCompliance.Generator.installPrivacyAPI();
        Logger.log('Removing installation of privacy API for now');
      } catch (err) {
        Logger.log('Privacy compliance generator for Install Privacy API Skipped due to error: ' + err.message);
      }
    }

    this.slots = new Slots({ app: this });
    this.slotNames = new SlotNames();
    this.logger = Logger;
    this.lifecycle = new Lifecycle();
    this.plugins = new PluginLoader({ app: this });

    createCommandQueue(this);
    incrementSessionDepth();

    NativeAds.listenForHymnalRenderEvents(this);

    installAdBlockerDetection().then(isBlocked => {
      this.adsBlocked = isBlocked; // this supports external code that needs to know if an adblocker is running.
      if (isBlocked) {
        Metrics.track('Ads Blocked', true);
      }
      if (process.env.NODE_ENV === 'test') {
        window.dispatchEvent(new Event('adBlockDetectorReady')); // this event exists to support tests only
      }
    });

    if (devtools) {
      devtools.emit('init', this);
    }
  };
}

/**
 * Establishes the command queue and fires initial commands passed.
 *
 * @param {ConcertAds} app
 */
function createCommandQueue(app) {
  app.commandQueue = app.settings.cmd || [];
  app.commandQueue.push = fn => {
    if (typeof fn === 'function') {
      try {
        fn.call(this, app);
      } catch (e) {
        Logger.log('Error processing command: ' + e.message, {
          level: 'warn',
        });
      }
    }
  };

  // Fire any commands sent before instantiation
  app.commandQueue.forEach(fn => app.commandQueue.push(fn));
}
