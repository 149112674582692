/* eslint-disable no-prototype-builtins */
import Slot from '../slot';
import {
  performanceMeasure,
  performanceMark,
  distanceFromBottom,
  closestTo,
  devtools,
  distanceOfElementBottomFromViewportBottom,
} from '../utils';
import Ad from '../ad';
import Bid from '../bid';
import Constants from '../constants';
import Logger from '../logger';
import Metrics from '../metrics';
import Events, { EventTypes } from '../events';
import { getRefreshRate, isRefreshDisabled } from '../refresh_rate';
import { getClosestBucketForValue } from '../../buckets';
import store from '../store';
import { scrollVelocityExceedsThreshold } from '../../plugins/scroll_velocity';
import GamAdSlotWrapper from '../slot_wrappers/gam_ad_slot_wrapper';

export default class GamAdSlot extends Slot {
  constructor(app, data) {
    super(app, data);

    // We also want to store the ID. Generally, we have just the prefix and the
    // name of the slot, but in case we want to change that, we can.
    this.id = this.data.id ? this.data.id : 'div-gpt-ad-' + this.name;

    // Create a new instance of Ad, which is responsible for displaying creative
    this.ad = new Ad(this);

    // Create a local targeting map for reference
    this.targeting = {};

    /**
     * Placeholder for the slot object returned by DFP eventually
     * @type {object}
     */
    this.slot = null;
  }

  /**
   * We take anything passed on as an extra option, which could include things
   * like this slot is not prebid eligible, and add it into the data we want
   * to store in the recorded slots.
   * @return {object} Official slot object
   */
  applyDefaults(refresh = false) {
    super.applyDefaults({ refresh });

    // Mark keys to exclude from data
    const exclude = ['name'];

    // These defaults live directly on the Slot object
    let slotDefaults = {
      sticky: false,
      sizes: [],
      customSizes: [],
      outOfPage: false,
      blockthrough: { dataId: null },
    };

    // We only want to set these if we are not refreshing.
    if (!refresh) {
      slotDefaults.renderedSize = false;
      slotDefaults.listeningForRefresh = false;
    }

    for (var def in slotDefaults) {
      if (slotDefaults.hasOwnProperty(def)) this[def] = slotDefaults[def];
    }

    for (var d in this.data) {
      if (exclude.indexOf(d) > -1) continue;
      if (this.data.hasOwnProperty(d)) this[d] = this.data[d];
    }

    // We add this in after we handle defaults because we want to make sure it
    // always overrides.
    if (refresh) {
      this.sizes = eligibleSizesForRefresh(this.renderedSize, this.sizes);
      this.holdSize = this.renderedSize;
    }

    // Now, we add in the attributes that under no circumstances do we want to
    // be able to override as we register this slot.
    const state = {
      prebidEligible: this.data.prebidEligible === false ? false : true,
      refreshEligible: this.data.refreshEligible === false ? false : true,
      hasBeenRefreshed: false,
      isBidding: false,
      queuedUntilAfterBid: false,
      loadTime: false,
      refreshQueued: false,
    };

    this._state = { ...this._state, ...state };

    autoDisablePrebid.call(this);

    return this;
  }

  // Should the slot be displayed now, or added to the lazy loading observer?
  canDisplayImmediately() {
    return this.isEager() && !this.isPrebidEligible();
  }

  /**
   * Determine whether this slot can be displayed on the page
   * @return {boolean}
   */
  canBeDisplayed() {
    // First things first, if we don't have sizes, we can't do a thing with you.
    if (this.sizes === undefined) {
      Logger.log('No sizes added.');
      return false;
    }

    return super.canBeDisplayed();
  }

  /**
   * Destroy this slot's wrapper and DOM element, and remove it from DFP.
   */
  destroy() {
    super.destroy();

    if (googletag.pubads) googletag.pubads().clear([this.slot]);
  }

  /**
   * Method that runs once the slot is inserted into the DOM
   * @return {undefined}
   */
  inserted() {
    if (!this.canBeDisplayed()) return;

    Events.emit(EventTypes.slotInserted, { slotName: this.name });

    const elem = this.getElement();

    if (elem) {
      elem.dispatchEvent(
        new CustomEvent('concertAdRegistered', { detail: { slotElement: elem }, bubbles: true, composed: true })
      );
    }

    // Publish it to DFP
    publishToDfp.call(this);

    // Create a new Bid for this slot
    if (!this.bid) {
      this.bid = new Bid(this);
    }

    this.canDisplayImmediately() ? this.render() : this.observe();
  }

  /**
   * Callback run when slot is sent away for Prebid
   */
  bidding() {
    this._state.isBidding = true;
    Events.emit(EventTypes.bidSent, { slotName: this.name });
  }

  /**
   * Callback run when slot is finished header bidding
   */
  bidded() {
    this._state.isBidding = false;
    Events.emit(EventTypes.bidComplete, { slotName: this.name });

    // If this slot is eager-loaded, show it now that we have finished prebid
    if (this.isEager() || this.isQueuedUntilAfterBid()) {
      this.render();
    }
  }

  /**
   * Callback run when DFP considers the slot to have contained a viewable impression.
   * @return {undefined}
   */
  viewed() {
    Events.emit(EventTypes.adViewed, { slotName: this.name });
    queueRefresh.call(this);
  }

  queueRefreshImmediately() {
    this._state.refreshQueued = false;
    clearTimeout(this.refreshTimer);
    queueRefresh.call(this, 0);
  }

  /**
   * Callback run when the slot is starting to be collapsed
   * @return {undefined}
   */
  collapsed() {
    super.collapsed();

    this._state.refreshEligible = false;
  }

  /**
   * Callback run when the observer marks the slot as visible.
   * @return {undefined}
   */
  observed() {
    Events.emit(EventTypes.slotObserved, { slotName: this.name });

    if (scrollVelocityExceedsThreshold()) {
      const scrollVelocity = store.get('scroll-velocity');

      Logger.log(`${this.name} observed, but scroll velocity ${scrollVelocity} exceeds threshold.`);
      Events.once(EventTypes.scrollVelocityBelowThreshold, () => this.observe());
      return;
    }

    if (this.isBidding()) {
      this.queueUntilAfterBid();
      Logger.log(`${this.name} observed, but queued until header bidding finished`);
    } else {
      this.render();
      Logger.log(`${this.name} scrolling into view and displaying`);
      this._state.displayEligible = false;
    }
  }

  /**
   * Callback run when DFP slot is loaded
   * @return {undefined}
   */
  loaded() {
    this._state.loadTime = Date.now() - this._state.loadTime;
    Events.emit(EventTypes.adLoaded, { slotName: this.name });

    performanceMark(`${this.name}-end`);
    performanceMeasure(`${this.name}`);

    Logger.log(`Slot ${this.name} loaded in ${this._state.loadTime}ms.`);

    if (!this._state.collapsed && this.renderedSize && !this._state.hasBeenRefreshed) {
      Metrics.track(`${this.getSizeString()}:loaded`, closestTo(this._state.loadTime, Constants.RESPONSE_TIME_BUCKETS));
      Metrics.track(
        `${this.getSizeString()}:loaded-location:`,
        bucketedRenderLocation(distanceFromBottom(this.getElement()))
      );
    }
  }

  /**
   * Callback run when DFP slot is rendered
   * @param {Event}     Event from DFP
   * @return {undefined}
   */
  rendered(evt) {
    this._state.rendered = true;
    const elem = this.getElement();
    const renderLocation = distanceFromBottom(elem);
    const renderedElementBottomLocation = distanceOfElementBottomFromViewportBottom(elem);

    // Update the render time
    this._state.renderTime = Date.now() - this._state.renderTime;
    this.timesRendered++;
    this.renderedSize = this.wrapper.getIframeSize();

    Events.emit(EventTypes.adRendered, {
      slotName: this.name,
      renderLocation,
      gamEvent: evt,
      renderedSize: this.renderedSize,
      timesRendered: this.timesRendered,
      renderedElementTopLocation: renderLocation,
      renderedElementBottomLocation,
      holdSize: this.getHoldSize(),
    });

    // Run callback on Ad object
    this.ad.rendered();

    // Run callback on SlotWrapper object
    this.wrapper.rendered(evt);

    // Record metrics
    if (!this._state.collapsed && this.renderedSize && !this._state.hasBeenRefreshed) {
      Metrics.track(
        `${this.getSizeString()}:rendered`,
        closestTo(this._state.renderTime, Constants.RESPONSE_TIME_BUCKETS)
      );
      Metrics.track(`${this.getSizeString()}:rendered-location`, bucketedRenderLocation(renderLocation));
    }

    elem.dispatchEvent(
      new CustomEvent('concertAdRendered', { detail: { slotElement: elem }, bubbles: true, composed: true })
    );
    Logger.log(`Rendering slot: ${this.name}`);
  }

  /**
   * Show an ad in this slot
   * @return {undefined}
   */
  render() {
    if (!super.render()) return;

    Promise.all(this.app.beforeAdsRequested).then(() => {
      // Show the ad.
      this._state.loadTime = Date.now();
      performanceMark(`${this.name}-start`);

      this.ad.show();

      if (devtools) {
        devtools.emit('flush');
      }
    });
  }

  /**
   * Refresh an ad slot. Called internally and in tests.
   * @return {undefined}
   */
  refresh() {
    // if a slot is being refreshed, we recreate the slot limiting it
    // only to the size that it was initially rendered at
    // so that the page does not jump
    Logger.log(
      `${this.name} being destroyed so we can rebuild it to only accept ${this.renderedSize[0]} and ${
        this.renderedSize[1]
      }`
    );

    // Preserve the height so there is no mobile jank
    this.wrapper.preserveHeight();

    // FYI, googletag should definitely be loaded by this point. Just not for tests.
    googletag.cmd.push(() => {
      // We then destroy the slot according to google.
      googletag.destroySlots([this.slot]);
    });

    // Reset the slot state
    this.applyDefaults(true);
    this.bid = null;

    this._state.hasBeenRefreshed = true;
    Events.emit(EventTypes.slotRefreshed, { slotName: this.name });

    // Kick off the inserted callback again
    this.inserted();
  }

  /**
   * Prevents a slot from rendering until prebid has finished
   */
  queueUntilAfterBid() {
    this._state.queuedUntilAfterBid = true;
  }

  isPrebidEligible() {
    return this._state.prebidEligible;
  }

  isBidding() {
    return this._state.isBidding;
  }

  isQueuedUntilAfterBid() {
    return this._state.queuedUntilAfterBid;
  }

  isRefreshEligible() {
    return this._state.refreshEligible && !isRefreshDisabled({ settings: this.app.settings });
  }

  isRefreshQueued() {
    return this._state.refreshQueued;
  }

  hasBeenRefreshed() {
    return this._state.hasBeenRefreshed;
  }

  shouldTrackRenderedEvent() {
    return true;
  }

  shouldTrackScrollVelocity() {
    return true;
  }

  /**
   * Set a slot as defined within DFP. While this is only called internally in this
   * class, it is also used during tests.
   *
   * This also calls a refresh listener for ads that might want to individually
   * disable refreshing.
   *
   * @return {undefined}
   */
  setDefined() {
    super.setDefined();

    if (!this.listeningForRefreshEligibility) {
      listenForRefreshEligibility.call(this);
    }

    if (!this.listeningForRefreshImmediately) {
      listenForRefreshImmediately.call(this);
    }
  }

  /**
   * Build the full GAM slug based on all arguments
   *
   * @return {String}
   */
  getGamSlug() {
    return this.app.settings.slug;
  }

  /**
   * Returns the name of the rendered size as `width x height` of the slot
   * ie: 300x250, 300x50, ...
   * @return {String} A string representation of the size.
   */
  getSizeString() {
    return this.renderedSize ? this.renderedSize.join('x') : '-';
  }

  /**
   * Mark a slot as prebid ineligible. Called from constructor and Bid.
   * @return {undefined}
   */
  markPrebidIneligible() {
    this._state.prebidEligible = false;
  }

  /**
   * Reset this slot's refresh queued status. Called from Ad.
   * @return {undefined}
   */
  resetRefreshQueued() {
    this._state.refreshQueued = false;
  }

  /**
   * Set targeting on the DFP slot
   * @param {string} key   Targeting key
   * @param {mixed} value  Targeting value
   */
  setTargeting(key, value) {
    this.targeting[key] = value;

    // It's possible that plugins will try to set targeting before the slot has been defined in GAM.
    // publishToDfp() will handle setting any previous targeting on the local object above.
    if (this.slot) {
      this.slot.setTargeting(key, value);
    }
  }

  /**
   * Get a value previously set as targeting using setTargeting()
   * @param {string} key Targeting key
   */
  getTargeting(key) {
    return this.targeting[key];
  }

  /**
   * Get the holdSize for a slot, falling back to preview if given
   * @return {mixed} Array [100,100] or null
   */
  getHoldSize() {
    return super.getHoldSize();
  }

  /**
   * Get slot wrapper
   * @param  {Slot}     slot       Instance of Slot
   * @param  {object}   config     Config
   * @param  {boolean}  existing   Whether we're creating from an existing DOM el
   * @return {GamAdSlotWrapper}         GamAdSlotWrapper instance
   */
  getSlotWrapper({ slot, config, existing }) {
    return new GamAdSlotWrapper({ slot, config, existing });
  }

  /**
   * Determine if a given DOM node is eligible to have a sibling ad rendered
   * @param  {Node} node   Sibling Element (before or after)
   * @return {boolean}
   */
  static canHaveSibling(node, config) {
    if (!node) {
      return true;
    }

    // If previous or next element isn't an ad, it can have a sibling
    if (!/\bm-ad\b/.test(node.className)) {
      return true;
    }

    let slot = node.children[0].__slot__;

    // If the slots share the same name, they shouldn't be placed next
    // to one another regardless of the allowSiblings setting
    if (slot && slot.name === config.name) {
      return false;
    }

    // Check if the slot can have a sibling
    if (slot && slot.data.allowSiblings) {
      return true;
    }

    return false;
  }

  static shouldSkip(app, slotConfig, neighbor) {
    let prev;
    let next;
    if (slotConfig.insertion === 'inside') {
      prev = neighbor.lastElementChild;
      next = neighbor.nextElementSibling;
    } else if (slotConfig.insertion === 'after') {
      prev = neighbor;
      next = neighbor.nextElementSibling;
    } else {
      prev = neighbor.previousElementSibling;
      next = neighbor;
    }

    if (!this.canHaveSibling(prev, slotConfig)) {
      return true;
    }

    if (!this.canHaveSibling(next, slotConfig)) {
      return true;
    }

    if (containerLimitMaxedOut.call(app, prev)) {
      Logger.log(`${slotConfig.name} wasn't rendered. The container has enough slots.`);
      return true;
    }

    if (slotConfig.sizes === undefined) {
      Logger.log(`${slotConfig.name} wasn't inserted. Sizes were not provided.`);
      return true;
    }

    slotConfig.sizes = reduceSizesByFilters(slotConfig, neighbor);

    if (!slotConfig.sizes.length) {
      Logger.log(`${slotConfig.name} has no more slot sizes after filters; skipping`);
      return true;
    }

    return false;
  }

  static isSlotTypeFor(config) {
    return !config.type || config.type === 'gam-ad';
  }
}

/**
 * Be able to nip prebid disabling in the bud if we marked the slot as
 * disabled.
 */
function autoDisablePrebid() {
  let prebidSettings = this.app.settings.prebid || {};
  let defaultConfig = prebidSettings.defaultConfig || {};

  if (
    (prebidSettings[this.configName] && prebidSettings[this.configName].disabled) ||
    (defaultConfig[this.configName] && defaultConfig[this.configName].disabled)
  ) {
    this._state.prebidEligible = false;
  }
}

/**
 * Build the GAM path
 *
 * @return {string}
 */
export function getGamSlugPath(slot) {
  let slugPath = slot.app.settings.slugPath || '';
  const forwardSlashString = '^/';
  const startsWithForwardSlashRegExp = new RegExp(forwardSlashString);

  if (slugPath !== '' && !startsWithForwardSlashRegExp.test(slugPath)) {
    slugPath = '/' + slugPath;
  }

  if (slot.app.settings.appendSlotNameToGAMSlug) {
    slugPath = slugPath + '/' + slot.configName;
  }

  if (typeof slot.data.pageNumberPosition === 'object' && slot.data.pageNumberPosition.name) {
    const pageNumberPositionName = slot.data.pageNumberPosition.name;
    const pageNumberPositionIncremented = slot.data.pageNumberPosition.increment;
    slugPath = slugPath + '/' + `${pageNumberPositionName}${pageNumberPositionIncremented ? slot.index + 1 : ''}`;
  }

  return slugPath;
}

/**
 * Publish the slot to DFP
 * @return {undefined}
 */
function publishToDfp() {
  if (this.isDefined()) {
    return false;
  }

  this.setDefined();

  googletag.cmd.push(() => {
    var adSlot;
    var slug = this.getGamSlug();
    var slugPath = getGamSlugPath(this);
    var fullGamSlug = slug + slugPath;
    var sizes = this.sizes;

    if (this.customSizes && this.customSizes.length > 0 && !this._state.hasBeenRefreshed) {
      var customSize = this.customSizes.filter(custom => {
        return this.index === custom.placement;
      });

      if (customSize.length > 0) {
        // make the custom slot filterable
        customSize[0].name = this.name;
        sizes = reduceSizesByFilters(customSize[0], this.element);
        Logger.log(`${this.name} using custom sizing ${sizes}`);
      }
    }

    // Get the slot object, after sending it all the information.
    if (this.outOfPage) {
      adSlot = googletag.defineOutOfPageSlot(fullGamSlug, this.id).setTargeting('slot_name', this.name);
    } else {
      adSlot = googletag.defineSlot(fullGamSlug, sizes, this.id).setTargeting('slot_name', this.name);
    }

    // Add service
    adSlot.addService(googletag.pubads());

    // Set the targeting position to the slot name.
    adSlot.setTargeting('position', this.name);

    // Only if we don't have a hold size do we want to collapse the empty div.
    // Note: We cannot do this on slots that we are going to watch— else they
    //       will never show up.
    if (this.getHoldSize() === null && !this.isWatcherEligible()) {
      adSlot.setCollapseEmptyDiv(true, true);
    }

    // Add custom slot targeting from config to targeting object
    if (this.data.targeting && typeof this.data.targeting === 'object') {
      Object.keys(this.data.targeting).forEach(key => {
        this.targeting[key] = this.data.targeting[key];
      });
    }

    // Adding Slot level variables for Pinnacle Sites
    // CBG To do: Change this to a config variable so it's more clear
    if (this.app.settings.slotVariables) {
      const adType = this.wrapper?.wrapperElement?.parentElement?.dataset?.adType
        ? this.wrapper?.wrapperElement?.parentElement?.dataset?.adType
        : '';

      const slotTargeting = {
        ...this.app.settings.slotVariables,
        pos_ps: this.configName,
        page_path: slugPath,
        adType,
      };
      this.targeting = { ...this.targeting, ...slotTargeting };
    }

    // Setting local slot targeting
    Object.keys(this.targeting).forEach(key => {
      Logger.log(this.name + ' custom targeting: ' + key + ' => ' + this.targeting[key]);
      adSlot.setTargeting(key, this.targeting[key]);
    });

    // Store the slot within our slot instance
    this.slot = adSlot;

    // Since we are now using disableInitialLoad(), this just sets up the slot
    // A network request is not made until we call refresh() inside the ad.js show method
    // Wrapping in DV Tag Ready Method
    if (typeof window.onDvtagReady === 'function') {
      window.onDvtagReady(() => {
        googletag.display(this.id);
      });
    } else {
      googletag.display(this.id);
    }

    Events.emit(EventTypes.slotPublishedToAdServer, { slot: adSlot, name: this.name });
  });
}

/**
 * A function that listens for an ad's enable/disabled automatic refresh
 * and changes it depending on the passed value
 */
function listenForRefreshEligibility() {
  // Bypass this if running a unit test, and no element exists
  if (!this.getElement()) {
    return;
  }

  this.listeningForRefreshEligibility = true;

  this.getElement().addEventListener('refreshEligibilityToggle', evt => {
    this._state.refreshEligible = evt.detail.hasOwnProperty('refresh') ? !!evt.detail.refresh : false;
    Logger.log(
      `The refresh eligibility event was fired for ${this.data.name} with state ${this._state.refreshEligible}`
    );
  });
}

function listenForRefreshImmediately() {
  if (!this.getElement()) {
    return;
  }

  this.listeningForRefreshImmediately = true;

  this.getElement().addEventListener('refreshImmediately', () => {
    this._state.refreshEligible = true;
    this.queueRefreshImmediately();
    Logger.log(`The refresh immediately event was received for ${this.data.name} `);
  });
}

/**
 * Queue up a refresh for this slot
 * @return {undefined}
 */
function queueRefresh(refreshRateOverride) {
  // this logic only applies if we can refresh the slot
  if (!this.isRefreshEligible() || this.isRefreshQueued()) {
    return false;
  }
  Logger.log(`Queueing refresh for slot ${this.name}`);
  let refreshRate = Number.isInteger(refreshRateOverride)
    ? refreshRateOverride
    : getRefreshRate({ settings: this.app.settings, slot: this });

  this._state.refreshQueued = true;
  this.refreshTimer = setTimeout(() => {
    // We can disable refeshing globally, and if we do we want to do that here.
    // Just to make sure we catch it if that changes between the timout and now, we capture that.
    if (this.app.settings.disableRefreshing) {
      Logger.log(`Refreshing disabled: slot ${this.name} has not been refreshed`);
    } else if (this.isRefreshEligible()) {
      Logger.log(`Starting refresh process for ${this.name}.`);
      this.refresh();
    }
  }, refreshRate);
}

/**
 * Will this slot be added to a container that has a limit on how many slots
 * can be added to it?
 * @param  {Node} node   Previous element/neighbor
 * @return {Boolean}     True if the container is maxed out; false if not.
 */
function containerLimitMaxedOut(node) {
  if (!this.app.settings.containerLimits) {
    return false;
  }

  return Object.keys(this.app.settings.containerLimits).some(selector => {
    if (!this.dom.querySelectorAll(selector).length) {
      return false;
    }

    // If the selector contains the node where we're inserting the ad, check
    // to see if this selector already has the max number of ads
    if (this.dom.querySelector(selector).contains(node)) {
      return (
        this.dom.querySelector(selector).querySelectorAll('.m-ad').length >= this.app.settings.containerLimits[selector]
      );
    }

    return false;
  });
}

/**
 * Reduce the default sizes to those that are within 5px of the rendered size
 * Created to account for custom sizes used in takeovers
 *
 * @param renderedSize: Array
 * @param defaultSizes: Array
 * @returns [number, number][] Sizes
 */
function eligibleSizesForRefresh(renderedSize, defaultSizes) {
  const threshold = 5;
  return defaultSizes.reduce((eligibleSizes, size) => {
    const widthInThreshold = Math.abs(size[0] - renderedSize[0]) <= threshold;
    const heightInThreshold = Math.abs(size[1] - renderedSize[1]) <= threshold;

    if (widthInThreshold && heightInThreshold) {
      Logger.log(`${size.join('x')} eligible for refresh.`);
      eligibleSizes.push(size);
    }

    return eligibleSizes;
  }, []);
}

/**
 * Reduce the sizes array by any filters specifed on individual sizes.
 *
 * @param {{ sizes: any, name: string }} slotConfig
 * @param {Element} neighbor Neighboring element
 * @returns [number, number][] Sizes
 */
function reduceSizesByFilters({ sizes, name }, neighbor) {
  return sizes.reduce((flattenedSizes, size) => {
    if (!size.size) {
      flattenedSizes.push(size);
      return flattenedSizes;
    }

    if (size.filters && !Slot.filterSlot(size, neighbor)) {
      Logger.log(`${name} had a slot size fail filters: ${size.size.join('x')}`);
    } else {
      flattenedSizes.push(size.size);
    }

    return flattenedSizes;
  }, []);
}

export function bucketedRenderLocation(location) {
  return getClosestBucketForValue({
    value: location,
    precision: 0.25,
    lowerBounds: -0.5,
    upperBounds: 1.75,
  });
}
