import Events, { EventTypes } from '../lib/events';
import Logger from '../lib/logger';
import PluginBase from './base';
import ScriptLoader from '../lib/script_loader';

export default class AdLightningSetup extends PluginBase {
  onInstall() {
    Logger.log('Installing plugin: AdLightningSetup');

    if (this.app.settings.adLightningReporting) {
      this.appendAdLightningScript();

      Events.on(EventTypes.adRendered, (_, { payload }) => {
        const { slotName, gamEvent, renderedSize, timesRendered } = payload;
        const slot = this.app.slots.get(slotName);

        // Removing div if already appended so we can add a new button each time the slot renders
        if (slot && slot.adLightningDivAppended) {
          this.removeAdLightningDiv(slot);
        }

        if (slot && !slot.adLightningReportingDisabled && !slot.adLightningDivAppended) {
          const adLightningId = `adl-report-${slot.id}-${timesRendered}`;
          this.appendAdLightningDiv(slot, renderedSize, adLightningId);
          this.enablingReportButton(slot, gamEvent, adLightningId);
        }
      });
    }
  }

  /**
   * Appending Ad Lightning script
   */
  async appendAdLightningScript() {
    const scriptObject = {
      url: 'https://tagan.adlightning.com/deseretdigital/op.js',
      timeout: 500,
      callback: null,
      customAttributes: {
        defer: true,
      },
    };

    Logger.log(`Loading Ad Lightning script`);
    const scriptLoader = new ScriptLoader();
    await scriptLoader.load(scriptObject);
  }

  /**
   * Appending Ad Lightning divs to slot wrapper element
   */
  appendAdLightningDiv(slot, renderedSize, adLightningId) {
    const slotWidth = renderedSize ? renderedSize[0] : '0px';
    const adLightningDiv = this.createAdLightningDivs(adLightningId, slotWidth);

    slot.wrapper.wrapperElement.appendChild(adLightningDiv);
    slot.adLightningDivAppended = true;
  }

  /**
   * Create Ad Lightning divs and return
   * @return {Node}
   */
  createAdLightningDivs(adLightningId, slotWidth) {
    const adLightningDiv = document.createElement('div');
    adLightningDiv.style.lineHeight = 1;
    adLightningDiv.style.margin = '0 auto';
    adLightningDiv.style.width = `${slotWidth}px`;
    adLightningDiv.classList.add('adl-report-wrapper');
    adLightningDiv.id = adLightningId;

    const adLightningChildDiv = document.createElement('div');
    adLightningChildDiv.classList.add('adl-report-ad-container');

    adLightningDiv.appendChild(adLightningChildDiv);

    return adLightningDiv;
  }

  /**
   * Removing Ad Lightning div
   */
  removeAdLightningDiv(slot) {
    const slotWrapperElement = slot.wrapper.wrapperElement;

    slotWrapperElement.childNodes.forEach(child => {
      if (child.classList.contains('adl-report-wrapper')) slotWrapperElement.removeChild(child);
    });

    slot.adLightningDivAppended = false;
  }

  /**
   * Sending message to Ad Lightning to enable report ad button
   */
  enablingReportButton(slot, gamEvent, adLightningId) {
    Logger.log(`Enabling Ad Lightning reporting button on ${slot.name} with adLightningId: ${adLightningId}`);

    // Create event for ADL to respond to
    const createReportBtnEvent = {
      adlAction: 'createUserReportButton',
      selector: `#${adLightningId}`,
      uniqueId: gamEvent ? gamEvent.creativeId || gamEvent.sourceAgnosticCreativeId : null,
      targetAd: slot.id,
    };

    window.postMessage(createReportBtnEvent, '*');
  }
}
