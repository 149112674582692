const lowercaseUserAgent = navigator.userAgent.toLowerCase();

export default {
  // param used to enable console logger
  DEBUG_ENABLED: window.location.href.indexOf('debug_concert_ads') >= 0,
  // param used for concert preview pages
  CONCERT_PREVIEW: window.location.href.indexOf('concert_preview') >= 0,
  IS_IOS: Boolean(
    lowercaseUserAgent.indexOf('ipod') !== -1 ||
      lowercaseUserAgent.indexOf('ipad') !== -1 ||
      lowercaseUserAgent.indexOf('iphone') !== -1
  ),
  IS_SAFARI: Boolean(lowercaseUserAgent.indexOf('safari') !== -1 && lowercaseUserAgent.indexOf('chrome') === -1),
  // We are instrumenting 1% of our traffic, unless on local or sandbox
  SHOULD_SEND_METRICS: Math.random() < 0.01 || location.href.indexOf('sbndev') != -1,
  // We are on production
  PRODUCTION: location.href.indexOf('sbndev') == -1,
  // prettier-ignore
  RESPONSE_TIME_BUCKETS: [
    50, 100, 250, 500, 750, 1000, 1250, 1500, 1750, 2000, 3000, 4000, 5000,
    6000, 7000, 8000, 9000, 10000, 11000
  ],
  // Countries that are considered restricted we need to get consent from in order to track
  RESTRICTED_COUNTRIES: [
    'AT',
    'BE',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES',
    'SE',
    'GB',
    'IS',
    'NO',
    'LI',
  ],
};
