import Events, { EventTypes } from '../lib/events';
import Logger from '../lib/logger';
import PluginBase from './base';
import ScriptLoader from '../lib/script_loader';

export default class AmazonBidding extends PluginBase {
  onSettingsLoaded() {
    if (this.biddingWithAmazon()) {
      Logger.log('Installing plugin: Amazon Bidding');

      Events.on(EventTypes.bidManagerCreated, () => {
        this.loadAmazonScript();
        this.app.bidManager.addBiddingPartner(this);
      });
    }
  }

  /**
   * Determine whether we are bidding with Amazon
   * @return {boolean}
   */
  biddingWithAmazon() {
    return (
      this.app.settings.prebid.biddingEnabled &&
      this.app.settings.prebid.amazonPubId &&
      (this.app.settings.prebid.amazonUrl || this.app.settings.prebid.loadAmazonScriptInConcertAds === false)
    );
  }

  /**
   * Loading the Amazon script
   * We are using the Bid Manager beforeFirstBid method to make sure the script loads before we start bidding
   * @return {undefined}
   */
  loadAmazonScript() {
    const dependency = {
      url: this.app.settings.prebid.amazonUrl,
      timeout: this.app.settings.prebid.amazonScriptTimeout || 3000,
    };

    this.app.bidManager.beforeFirstBid(() => {
      return new Promise((resolve, reject) => {
        if (
          this.app.settings.prebid.loadAmazonScriptInConcertAds === false ||
          document.querySelector(`script[src="${dependency.url}"]`)
        ) {
          Logger.log(`Amazon script already exists in the DOM.`);
          setUpAmazon(this.app);
          resolve();
        } else {
          new ScriptLoader()
            .load(dependency)
            .then(url => {
              Logger.log(`Amazon (TAM) script ${url} has loaded successfully.`);
              setUpAmazon(this.app);
              resolve();
            })
            .catch(error => {
              Logger.error(`Error loading Amazon script: ${error}`);
              // We still want to set up Amazon even if the script takes a long time to load
              setUpAmazon(this.app);
              reject(error);
            });
        }
      });
    });
  }

  /**
   * Build up the Amazon bid queues
   * @param  {Array} array of bids
   * @return {undefined}
   */
  buildBiddingQueues(queue) {
    const biddingQueue = [];

    queue.forEach(bid => {
      biddingQueue.push({
        slotID: bid.slot.id,
        slotName: bid.app.settings.slug,
        sizes: bid.slot.sizes,
      });
    });

    return biddingQueue;
  }

  /**
   * Fetch bids from Amazon.
   * @type {Promise}
   */
  fetchBidsFor({ queueOfBids, timeout }) {
    if (typeof apstag === 'undefined' || typeof apstag.setDisplayBids !== 'function') return;

    return new Promise(resolve => {
      Logger.log('Fetching bids with Amazon');

      apstag.fetchBids(
        {
          slots: this.buildBiddingQueues(queueOfBids),
          timeout,
        },
        resolve
      );
    }).catch(() => {
      Logger.log('There was an error fetching bids with Amazon');
    });
  }

  /**
   * Add Amazon targeting to bids
   */
  addTargeting() {
    if (typeof apstag === 'undefined' || typeof apstag.setDisplayBids !== 'function') return;
    Logger.log('Adding Amazon targeting to bids.');
    apstag.setDisplayBids();
  }
}

/**
 * Initialize apstag library and set config to handle setting bid targeting.
 */
function setUpAmazon(app) {
  /* prettier-ignore */
  if (!window.apstag) {
    !function(a9, a) {
      if(a[a9]) return;
      function q(c, r) { a[a9]._Q.push([c, r]); }
      a[a9] = {
        init: function() { q('i', arguments); },
        fetchBids: function() { q('f', arguments); },
        _Q: []
      };
    }('apstag', window);
  }

  Logger.log('Setting up Amazon');
  if (app.settings.prebid.amazonPubId) {
    apstag.init({
      pubID: app.settings.prebid.amazonPubId,
      adServer: 'googletag',
      videoAdServer: 'connatix',
      bidTimeout: app.settings.prebid.amazonTimeout || 1000,
      deals: true,
    });
  }
}
