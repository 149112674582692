import PluginBase from './base';
import store from '../lib/store';
import events, { EventTypes } from '../lib/events';
import { CONCERT_ID_KEY } from './concert_id';
import { getClosestBucketForValue } from '../buckets';
import logger from '../lib/logger';

const SAMPLE_SIZE = 5 / 100;

export default class ConcertTracking extends PluginBase {
  onInstall() {
    if (!this.app.settings.concertTrackingEnabled) {
      logger.log('Concert tracking disabled for this site');
      return;
    }

    this.shouldSend = sampleSize(SAMPLE_SIZE);

    // TODO: Uncomment once we're ready to send metrics to a data collection service
    // this.trackSlotRenderedEvents();
  }

  trackSlotRenderedEvents() {
    events.on(EventTypes.adRendered, (eventName, { payload }) => {
      const { slotName, renderLocation } = payload;
      const slot = this.app.slots.get(slotName);

      if (!slot.shouldTrackRenderedEvent()) return;

      const prebidDelta = isNaN(store.get('auction-ended')) ? 0 : Date.now() - store.get('auction-ended');

      // See Phonograph event schema: https://github.com/voxmedia/phonograph/issues/44
      const data = {
        concert_rid: slot.getTargeting(CONCERT_ID_KEY),
        experiment_id: store.get('concert-experiment-id'),
        variant_id: store.get('concert-experiment-variation-id'),
        scroll_velocity: store.get('scroll-velocity-timeseries'),
        geo_region: store.get('geo-region'),
        render_location: bucketedRenderLocation(renderLocation),
        referrer: referrerFromSession(),
        prebid_timeout: this.app.settings.prebid.timeout,
        prebid_partner_timeouts: store.get('timedout-bidders') || [],
        prebid_delta: prebidDelta,
        refresh_rate: this.app.settings.refreshRate,
        observer_threshold: this.app.settings.observerThreshold,
        scroll_velocity_threshold: Number(this.app.settings.scrollVelocityThreshold),
      };

      logger.log(`ConcertTracking data for slot (${this.shouldSend ? 'sent' : 'NOT sent'})`, data);

      if (this.shouldSend) {
        // TODO: Send to data collection service
      }
    });
  }
}

/**
 * Whether a random number fits a given sample size.
 * @param {float} sample Sample size, between 0 and 1
 */
function sampleSize(sample) {
  return sample >= Math.random();
}

function bucketedRenderLocation(location) {
  return getClosestBucketForValue({
    value: location,
    precision: 0.1,
    lowerBounds: -0.5,
    upperBounds: 1.75,
  });
}

/**
 * Get the referrer for this session from our sessionStorage.
 * Or set it, if it's the first visit in the session.
 */
function referrerFromSession() {
  if (typeof window.sessionStorage === 'undefined') {
    return '';
  }

  const referrerFromStorage = sessionStorage.getItem('concert-referrer');

  if (referrerFromStorage !== null) {
    return referrerFromStorage;
  }

  const referrer = document.referrer;
  sessionStorage.setItem('concert-referrer', referrer);

  return referrer;
}
